export const ACCOUNTINGCODES = "/accountcodes";
export const ACTIVE_USER = "/activeuser";
export const ADDENDUMS = "/addendums";
export const ADDITIONAL_TITLE_SERVICES = "/additionaltitleservices";
export const ADDRESS_TYPES = "/addresstypes";
export const AGGREGATE_ADJUSTMENTS = "/aggregateAdjustments";
export const AUDITLOG = "/auditlog";
export const AUTOCONSOLIDATEPAYEES = "/autoconsolidatepayees";
export const BISETTINGS = "/bisettings";
export const BRANCHES = "/branches";
export const BUYER_SELLER_RELATIONSHIP = "/buyersellerrelationship";
export const CASH_TO_CLOSE = "/cashtoclose";
export const CDF_PAYOFFS = "/cdfPayoffs";
export const CHECK_COVER_LETTERS = "/checkcoverletters"
export const CHECK_WRITING = "/checkWriting";
export const CLOSING_TYPES = "/closingtypes";
export const CM_E_REMIT = "/cmERemit";
export const CM_CLOSING_PROTECTION_LETTERS = "/cmClosingProtectionLetters";
export const COMMERCIAL_SETTLEMENT_STATEMENT = "/commercialsettlementstatement"
export const COMMITMENTS = "/commitments";
export const COMPANIES = "/companies";
export const COMPANY_ADDRESSES = "/CompanyAddresses";
export const COMPANY_ROLES = "/companyRoles";
export const CONFIGWORKFLOWTASKS = "/configworkflowTasks";
export const CONSOLIDATED_CHECKS = "/consolidatedchecks";
export const CONTACTS = "/contacts";
export const CUSTOM_DATA = "/customdata";
export const DEBIT_CREDITS = "/debitCredits";
export const DEFAULTCDFLINES = "/defaultcdflines";
export const DEFAULTHUDLINE = "/defaulthudlines";
export const DELIVERY_METHODS = "/workflowservicerequestdeliverymethods";
export const DEPOSIT_SLIPS = "/depositSlips";
export const DO_NOT_DO_BUSINESS_WITH = "/doNotDoBusinessWith";
export const DOCUMENTPACKAGESECURITYUSERS = "/documentPackageSecurityUsers";
export const DOCUMENTS = "/documents";
export const DOCUMENTSECURITYUSERS = "/documentSecurityUsers";
export const DOCUMENT_CATEGORIES = "/documentcategories";
export const DOCUMENT_PACKAGES = "/documentpackages";
export const DOCUMENT_SERVICE = "/documentservice";
export const DOCUMENT_TEMPLATES = "/documenttemplates";
export const DOCUMENT_REPORTS = "/documentreports";
export const DUPLICATESEARCH = "/duplicatesearch";
export const AUTORECONMAPPINGS = "/autoreconmappings";
export const EBANKRECON = "/ebankrecon";
export const ENDORSEMENTS = "/endorsements";
export const ESCROWACCOUNTSETTINGS = "/escrowaccountsettings";
export const ESCROWS = "/escrows";
export const ESCROWUNITS = "/escrowunits";
export const ESCROW_ACCOUNTING = "/escrowAccounting";
export const EVENT = "/event";
export const EXAGO = "/exago";
export const EXPORTS = "/export";
export const FILENUMBERPOOLS = "/filenumberpools";
export const FILESCAN = "/fileScan";
export const FILESCANCATEGORIES = "/filescancategories";
export const FILESCANCATEGORYSECURITYUSERS = "/FileScanCategorySecurityUsers";
export const FILESCANDESCRIPTIONS = "/fileScanDescriptions";
export const FILESCANDESCRIPTIONTASKS = "/fileScanDescriptionTasks";
export const FILE_1099_REPORT = "/file1099Report";
export const HEALTH = "/health";
export const HUD_ADDENDUMS = "/hudAddendums";
export const HUD_GFES = "/hudGFEs";
export const HUD_STATUSES = "/hudStatuses";
export const IMPORTUPDATE = "/importupdate";
export const INTERNETALERTS = "/internetalerts";
export const INVOICES = "/invoices";
export const LICENSE = "/license";
export const LISTCODES = "/listCodes";
export const LOANCOST_CHARGES = "/loancosts";
export const LOAN_CALCULATION = "/loancalculation";
export const LOAN_DISCLOSURES = "/loandisclosures";
export const LOAN_TERMS = "/loanterms";
export const LOOKUPS = "/lookups";
export const LOSSADVANCEREASONCATEGORIES = "/lossadvancereasoncategories";
export const LOSSADVANCEREASONS = "/lossadvancereasons";
export const MERGEDOCS = "/merge";
export const MONEYMOVEMENTAPPROVALRANGES = "/moneymovementapprovalranges";
export const MONEYMOVEMENTAPPROVALS = "/moneyMovementApprovals";
export const MONEYMOVEMENTAPPROVALTHRESHOLDS = "/moneyMovementApprovalThresholds";
export const MORTGAGE_TYPES = "/mortgageTypes";
export const NET_SETTLEMENT = "/netDisbursement";
export const NOTE_CATEGORIES = "noteCategories";
export const NOTIFICATION = "/notification";
export const NUMBERRANGES = "/policynumberranges";
export const ORDERCATEGORIES = "/ordercategories";
export const ORDERS = "/orders";
export const ORDER_CUSTOM_DATA = "/ordercustomdata";
export const ORDER_SETTLEMENT_FEES = "/orderSettlementFees";
export const ORDER_SIGNING = "/ordersignings"
export const ORDER_WORKFLOW = "/orders/orderworkflow";
export const PAID_BY_OTHERS = "/paidbyothers";
export const PAYOFFDESCRIPTIONS = "/payoffdescriptions";
export const PAYOFFS = "/payoffs";
export const POLICY_FORM_TYPES = "/policyformtypes";
export const RECORDING_TYPES = "/recordingtypes";
export const RECORDING_STATUSES = "/recordingstatuses";
export const RECORDING_REJECTIONS = "/recordingrejections";
export const SIGNING_STATUSES = "/signingstatuses";
export const POLICYPOOLS = "/policynumberpools";
export const PREPAIDS = "/prepaids";
export const PROJECTED_PAYMENT = "/projectedPayment";
export const PROPERTIES = "/orderProperties";
export const PROPERTYLAYOUTS = "/propertylayouts";
export const PROPERTYTYPES = "/propertytypes";
export const PRORATE_REGION_DEFAULTS = "/prorateRegionDefaults";
export const PRORATIONS = "/prorations";
export const RATES = "/rates";
export const RATE_ENGINE = "/rateengine";
export const READY2CLOSE_CHANGE_TRACKING = "ready2CloseChangeTracking"
export const READY2CLOSE_CONFIGURATION = "/ready2close";
export const READY2CLOSE_SYSTEM = "/ready2closeSystem";
export const REALTOR_COMMISSION = "/realtorcommission";
export const RECONCILIATIONS = "/reconciliations";
export const RECORDINGFEECONFIGURATION = "/recordingFeeConfiguration";
export const RECORDING_DOCUMENTS = "/recordingDocuments";
export const RECORDING_FEES = "/recordingfees";
export const RECORDING_INFO = "/recordingInfo";
export const RECORDING_ITEMIZATION = "/recordingitemization";
export const REGIONS = "/regions";
export const REPORTS = "/report";
export const SCHEDULERDEFAULTS = "/schedulerDefaults";
export const SETTLEMENT_DASHBOARD = "/settlementdashboard";
export const SETTLEMENT_FEES = "/settlementFees";
export const SETTLEMENT_ORDER_SUMMARY = "/settlementordersummary";
export const SETTLEMENT_STATEMENT = "/settlementstatement";
export const SETTLEMENT_STATEMENT_CONTACTS = "/settlementstatementcontacts";
export const SMKT_EZ_JACKET = "/smktEzJacket";
export const SOURCESOFBUSINESS = "/sourcesofbusiness";
export const STAFF = "/staff";
export const STANDARDLANGUAGE = "/standardlanguage";
export const STANDARDLANGUAGECATEGORY = "/standardlanguagecategory";
export const SUBDIVISIONS = "/subdivisions";
export const SURVEYS = "/surveys";
export const SYSTEM = "/system";
export const SYSTEMDEFAULT = "/systemdefault";
export const SYSTEMS = "/systems";
export const TAGS = "/tags";
export const TAXRATES = "/taxrates";
export const TITLEUNITS = "/titleunits";
export const TITLE_PROD_CUSTOM_TABS = "/titleProdCustomTabs";
export const TOKEN = "/token";
export const TRANSACTION_TYPES = "/transactionTypes";
export const TRANSFER_SLIPS = "/transferSlips";
export const TRANSFER_TAXES = "/transfertaxes";
export const TRANSFER_TAX_CONFIGURATION = "/transferTaxConfiguration";
export const TRANSFER_TAX_RATES = "/transferTaxRates";
export const TYPEFUNDS = "/typefunds";
export const USERS = "/users";
export const GRIDSTATES = "/gridStates";
export const UTILITIES = "/utils";
export const VESTINGINFORMATION = "/vestinginformation";
export const WEB_DEFAULTS = "/webdefaults";
export const WORKFLOWDASHBOARDSETTINGS = "/workflowdashboardsettings";
export const WORKFLOWDEPARTMENTS = "/workflowdepartments";
export const WORKFLOWTASKS = "/workflowtasks";
export const WORKFLOWTASKPACKAGES = "/workflowtaskpackages";
export const WORKFLOWTASKPACKAGECATEGORIES = "/workflowtaskpackagecategories";
export const WORKFLOW_PROCESS_TEMPLATES = "/workflowProcessTemplates";
export const WORKSHEET_801 = "/worksheet801";
export const WYSIWYG_CDF = "/wysiwygcdf"
export const MORTGAGE_CLAUSE_TYPES = "/mortgageClauseTypes";
export const EPICORLOCATIONS = "/workdayLocations";
export const EPICORPRODUCTS = "/workdayProducts";
export const EPICORBANKCODES = "/workdayBankCodes";
export const EPICORFILEGENERATION = "/workday";
export const EPICORUNDERWRITERSPLITRATES = "/workdayUnderwriterSplitRates";
export const EPICORVALIDPAYEES = "/workdayValidPayees";
export const ORDER_TIME_TRACKING_CATEGORIES = "/orderTimeTrackingCategories";
export const ORDER_TIME_TRACKING = "/orderTimeTracking";
export const ORDER_TIME_TRACKING_ACCOUNT_CODES = "/orderTimeTrackingAccountCodes";
export const ESCHEAT_DORMANCY_PERIOD = "/escheatDormancyPeriod";
