import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getAutomationSchema() {
        return HttpWrapper.get(`${endpoints.EVENT}/automationSchema`);
    },

    getEventConfigs() {
        return HttpWrapper.get(`${endpoints.EVENT}/config`);        
    },

    addEventConfig(eventConfig) {
        return HttpWrapper.post(`${endpoints.EVENT}/config`, eventConfig);        
    },
    
    deleteEventConfig(id) {
        return HttpWrapper.delete(`${endpoints.EVENT}/config/${id}`);
    },
};
