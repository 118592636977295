import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getList (loanId) { return HttpWrapper.get(`${endpoints.RECORDING_INFO}/${loanId}`); },
    getFee (loanId, lineType, lineTypeId) { return HttpWrapper.get(`${endpoints.RECORDING_INFO}/fee/${loanId}/lineType/${lineType}/${lineTypeId}`); },
    save (data) { return HttpWrapper.post(endpoints.RECORDING_INFO, data, null); },
    autogenerate(loanId) { return HttpWrapper.post(`${endpoints.RECORDING_INFO}/autogenerate`, {loanId}, null); },
    getAll (searchRequest) { return HttpWrapper.post(`${endpoints.RECORDING_INFO}/getAll`, searchRequest); },
    reorder (reorderRequest) {return HttpWrapper.post(`${endpoints.RECORDING_INFO}/reorder`, reorderRequest); },
    createPackageRequest (data) { return HttpWrapper.post(`${endpoints.RECORDING_INFO}/packageRequest`, data, null); },
    savePackage (id, data) { return HttpWrapper.put(`${endpoints.RECORDING_INFO}/package/${id}`, data, null); },
    getPackageRequest (id) { return HttpWrapper.get(`${endpoints.RECORDING_INFO}/packageRequest/${id}`); },
    createPackage (id) { return HttpWrapper.post(`${endpoints.RECORDING_INFO}/package/${id}`, null); },
};