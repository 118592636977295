import { HttpWrapper } from '../shared/services/rest.service';
import { DO_NOT_DO_BUSINESS_WITH } from "./endpoints";

export default {
    getWarnings(orderId){return HttpWrapper.get(`${DO_NOT_DO_BUSINESS_WITH}/order/${orderId}/warnings`)},
    search(name, address, type, source, effectiveDateFrom, effectiveDateTo, includeInactive){ return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/search`, { name, address, type, source, effectiveDateFrom, effectiveDateTo, includeInactive}); },
    toggleInactive(doNotDoBusinessWithIDs){ return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/toggleInactive`, doNotDoBusinessWithIDs); },
    getByEntityName(entity){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/entity/`, {entity})},
    getByEntityList(entities){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/entities/`, {entities})},
    getByPropertyName(address){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/property/`, {address})},
    getByCompanyName(company){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/company/`, {company})},
    getByCompanyList(companies){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/companies/`, {companies})},
    getByIndividualName(orderId){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/order/contact/${orderId}`)},
    getDoNotDoBusinessWithFile(doNotDoBusinessWithID){return HttpWrapper.post(`${DO_NOT_DO_BUSINESS_WITH}/getDoNotDoBusinessWithFile/${doNotDoBusinessWithID}`)},
    save(data) {
        return HttpWrapper.put(`${DO_NOT_DO_BUSINESS_WITH}`, { data: data} );
    },
}