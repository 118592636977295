import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default{
    getEzJacketData(ordersID) { return HttpWrapper.get(`${endpoints.SMKT_EZ_JACKET}/${ordersID}/ezJacketData`); },
    setScheduleData(orderId, policyId, ids) { return HttpWrapper.post(`${endpoints.SMKT_EZ_JACKET}/${orderId}/${policyId}/ezJacketScheduleData`, ids); },
    addOrder(ordersID, id) { return HttpWrapper.post(`${endpoints.SMKT_EZ_JACKET}/${ordersID}/${id}`, null); },
    getPolicyData(ordersID) { return HttpWrapper.get(`${endpoints.SMKT_EZ_JACKET}/${ordersID}/jacketPolicyData`, null); },
    remitOrder(ordersID, id, paymentMethod) { return HttpWrapper.put(`${endpoints.SMKT_EZ_JACKET}/${ordersID}/${id}/remit?paymentMethod=${paymentMethod}`, null); },
    voidOrder(ordersID, id, reason) { return HttpWrapper.put(`${endpoints.SMKT_EZ_JACKET}/${ordersID}/${id}/void?voidReason=${encodeURIComponent(reason)}`, null); }
}